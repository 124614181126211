<template>
  <router-view />
</template>
<script lang="ts">
import { defineComponent } from 'vue';
const { VITE_ENV } = import.meta.env;
if (VITE_ENV === 'production') {
  // 阻止右键菜单
  document.addEventListener('contextmenu', function (event) {
    event.preventDefault();
  });

  // 阻止F12键
  document.addEventListener('keydown', function (event) {
    if (event.key === 'F12') {
      event.preventDefault();
    }
  });
}

export default defineComponent({
  name: 'app',
});
</script>
