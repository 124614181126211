<script setup lang="ts">
import { onMounted, ref } from 'vue';

import Pc from './component/pc/index.vue';
import Phone from './component/phone/index.vue';

import { getUserAgent } from '@/utils/index';

const isPc = ref<any>(getUserAgent());

onMounted(() => {
  // 添加窗口大小变化的事件监听器
  window.addEventListener('resize', function () {
    // 窗口大小发生变化时执行的操作
    isPc.value = getUserAgent();
    if (getUserAgent() === 'pc') {
      document.documentElement.style.fontSize = '';
    }
  });
});
</script>

<template>
  <div v-if="isPc !== ''" class="main">
    <Pc v-if="isPc === 'pc'" />
    <Phone v-else />
  </div>
</template>

<style lang="scss" scoped>
.main {
  width: 100%;
  min-height: 100%;
  background: #111112;
  overflow: hidden;
}
</style>
