<script setup lang="ts">
import { onBeforeUnmount, onMounted, reactive, ref } from 'vue';

import { Swiper, SwiperSlide } from 'swiper/vue';

import play from '@/assets/play.png';

import { Controller } from 'swiper/modules';
import { useI18n } from 'vue-i18n';

import { getInfocardList } from '@/api';

const { t, locale } = useI18n();
const props: any = defineProps({
  activeSection: Array,
});
const pageObj: any = reactive({ page: 1, page_size: 100 });
const videoList = ref<any[]>([]);

const currentIndex = ref(0);
const mySwiper: any = ref(null);
// const userInteracted = ref(false);
const togglePlay = (id: string) => {
  videoList.value.forEach((item, index) => {
    const video: any = document.getElementById(`video-${index}`);
    if (!video.paused) {
      item.isPlaying = false;
      video.pause();
    }
    if (id === `video-${index}`) {
      if (video.paused) {
        video.requestFullscreen();
        video.play();
        item.isPlaying = true;
      } else {
        video.pause();
      }
    }
  });
};
const handleFullscreenChange = () => {
  const doc: any = document;
  if (
    !doc.fullscreenElement &&
    !doc.webkitFullscreenElement &&
    !doc.mozFullScreenElement &&
    !doc.msFullscreenElement
  ) {
    videoList.value.forEach((item, index) => {
      const video: any = document.getElementById(`video-${index}`);
      if (!video.paused) {
        video.pause();
        item.isPlaying = false;
      }
    });
    // 这里添加退出全屏时的处理逻辑
  }
};

const setControlledSwiper = (swiper: any) => {
  mySwiper.value = swiper;
};

const onSlideChange = (swiper: any) => {
  currentIndex.value = swiper.activeIndex;
};

const slideTo = (type: string) => {
  if (type === 'left') {
    mySwiper.value.slidePrev();
  } else {
    mySwiper.value.slideNext();
  }
};

const preloadVideo = () => {
  const videos = document.querySelectorAll('video');
  videos.forEach((video) => {
    video.preload = 'auto';
    video.load();
  });
};

const getVideoList = async () => {
  try {
    let res: any = await getInfocardList(pageObj);
    if (res.code === 200) {
      let tempArr: any = [];
      res.data.list.forEach((item: any) => {
        item.isPlaying = false;
        tempArr.unshift(item);
      });
      videoList.value = tempArr;
    }
  } catch (error) {
    console.log(error);
  }
};

onMounted(() => {
  getVideoList();
  preloadVideo();
  document.addEventListener('fullscreenchange', handleFullscreenChange);
  document.addEventListener('webkitfullscreenchange', handleFullscreenChange);
  document.addEventListener('mozfullscreenchange', handleFullscreenChange);
  document.addEventListener('msfullscreenchange', handleFullscreenChange);
});

onBeforeUnmount(() => {
  document.removeEventListener('fullscreenchange', handleFullscreenChange);
  document.removeEventListener(
    'webkitfullscreenchange',
    handleFullscreenChange
  );
  document.removeEventListener('mozfullscreenchange', handleFullscreenChange);
  document.removeEventListener('msfullscreenchange', handleFullscreenChange);
});
</script>

<template>
  <div class="technology" :class="{ 'technology-zh': locale === 'zh' }">
    <div class="title" id="title-4">
      <div :class="{ 'fade-up': props.activeSection.includes('title-4') }">
        {{ t('about.technology.title') }}
      </div>
    </div>
    <div
      class="subtitle"
      :class="{ 'fade-up': props.activeSection.includes('title-4') }"
    >
      {{ t('about.technology.decs') }}
    </div>
    <div class="technology-content">
      <swiper
        :controller="{ control: mySwiper }"
        :modules="[Controller]"
        :free-mode="true"
        @slideChange="onSlideChange"
        :slidesPerView="2.5"
        :spaceBetween="30"
        class="technologySwiper"
        :class="{
          'right-shadow': currentIndex !== videoList.length - 2,
          'left-shadow': currentIndex === videoList.length - 2,
        }"
        @swiper="setControlledSwiper"
      >
        <swiper-slide
          @click="togglePlay(`video-${index}`)"
          class="techSwiper-slide"
          v-for="(item, index) in videoList"
          :key="index"
        >
          <div class="slide-source">
            <img class="play" :src="play" alt="" />
            <img :id="`img-${index}`" :src="item.photo_pc_url" alt="" />
            <video
              :id="`video-${index}`"
              :poster="item.photo_pc_url"
              muted
              webkit-playsinline="true"
              playsinline="true"
            >
              <source :src="item.video_pc_url" type="video/mp4" />
            </video>
            <!-- <div class="overlay" v-if="!item.isPlaying">
              <span class="overlay-span">{{
                t(`about.technology.list[${index}]`)
              }}</span>
            </div> -->
          </div>
          <div class="slide-decs">
            {{ locale === 'zh' ? item.content : item.eng_content }}
          </div>
        </swiper-slide>
      </swiper>
      <!-- v-if="currentIndex !== techs.length - 2" -->
      <div
        class="icon-right"
        :class="{
          'active-right': currentIndex !== videoList.length - 2,
          'default-right': currentIndex > 0,
        }"
        @click="slideTo('right')"
      ></div>
      <!-- v-if="currentIndex > 0" -->
      <div
        class="icon-left"
        :class="{
          'active-left': currentIndex > 0,
          'default-left': currentIndex !== videoList.length - 2,
        }"
        @click="slideTo('left')"
      ></div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.technology {
  max-width: 1640px !important;
  margin: 0 auto;
  .title {
    color: #fff;
    font-family: Arial;
    font-size: 68px;
    font-style: normal;
    font-weight: 400;
    text-align: center;
    margin-bottom: 12px;
    overflow: hidden;
    > div {
      opacity: 0;
      transform: translateY(110%);
      transition: opacity 1s, transform 0.5s;
    }
  }
  .fade-up {
    opacity: 1 !important;
    transform: translateY(0) !important;
  }
  .subtitle {
    // height: 36px;
    color: rgba(180, 188, 208, 0.37);
    font-family: MyRegular;
    font-size: 23px;
    font-style: italic;
    text-align: center;
    line-height: 30px;
    font-weight: 600;
    margin: 0 auto;
    opacity: 0;
    transform: translateY(110%);
    transition: opacity 1s, transform 0.5s;
  }
  .flex-between {
    display: flex;
    justify-content: space-between;
  }
  &-content {
    position: relative;
    padding-bottom: 140px;
    .technologySwiper {
      position: relative;
      padding-top: 48px;
      overflow: hidden;
      padding-bottom: 10px;
      padding-left: 15px;
      padding-right: 10px;
      .techSwiper-slide {
        cursor: pointer;
        // width: 625px !important;
        margin-right: 20px;
        .slide-source {
          cursor: pointer;
          position: relative;
          width: 100%;
          // width: 595px !important;
          height: 400px;
          border-radius: 10px;
          scale: 1;
          transition: scale 0.3s;
          video {
            position: absolute;
            cursor: pointer;
            width: 100%;
            height: 100%;
            border-radius: 10px;
            z-index: -1;
          }
          // .overlay {
          //   position: absolute;
          //   bottom: -1px;
          //   left: -1px;
          //   width: 462px;
          //   height: 150px;
          //   // background: linear-gradient(
          //   //   to top,
          //   //   rgba(0, 0, 0, 0.8),
          //   //   rgba(255, 126, 95, 0)
          //   // );
          //   display: flex;
          //   justify-content: center;
          //   align-items: center;
          //   opacity: 0;
          //   transition: opacity 0.3s;
          //   z-index: 10;
          //   border-radius: 10px;
          //   &-span {
          //     display: block;
          //     width: 100%;
          //     text-align: left;
          //     position: absolute;
          //     font-family: Verdana;
          //     color: #f8faff;
          //     letter-spacing: 0.37px;
          //     font-size: 14px;
          //     padding: 0 39px;
          //     bottom: 30px;
          //     box-sizing: border-box;
          //   }
          // }
          .play {
            width: 50px;
            height: 50px;
            position: absolute;
            left: 50%;
            opacity: 1;
            top: 50%;
            transform: translate(-50%, -50%);
            cursor: pointer;
            z-index: 11;
          }
          img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            cursor: pointer;
            width: 100%;
            height: 100%;
            border-radius: 10px;
            z-index: 10;
          }
          &:hover {
            scale: 1.04;
          }
        }
        // .slide-source:hover .overlay {
        //   opacity: 1;
        // }
        // .slide-source:hover .play {
        //   opacity: 1;
        // }
        .slide-decs {
          margin: 28px 0;
          color: #b4bcd0;
          opacity: 0.9;
          font-family: MyRegularLight;
          font-size: 20px;
          font-style: normal;
          font-weight: 300;
          font-weight: 400;
          line-height: 28px;
        }
      }
      .techSwiper-slide:last-child {
        margin-right: 0px;
      }
    }
    .left-shadow {
      &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 80%;
        left: 0;
        background: linear-gradient(to right, rgba(19, 19, 20), transparent);
        pointer-events: none; /* 使阴影不影响点击事件 */
        z-index: 99999;
      }
    }
    .right-shadow {
      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 80%;
        background: linear-gradient(to left, rgba(19, 19, 20), transparent);
        pointer-events: none; /* 使阴影不影响点击事件 */
        z-index: 99999;
      }
    }
    .default-left {
      background: url('../../../../assets/icon_left.png') no-repeat;
    }
    .default-right {
      background: url('../../../../assets/icon_right.png') no-repeat;
    }
    .icon-right {
      cursor: no-drop;
      position: absolute;
      bottom: 120px;
      right: 5px;
      width: 50px;
      height: 50px;
      background: url('../../../../assets/icon_right_disable.png') no-repeat;
      background-size: contain;
      z-index: 10;
      transition: background 0.3s;
    }
    .icon-left {
      cursor: no-drop;
      position: absolute;
      bottom: 120px;
      right: 80px;
      width: 50px;
      height: 50px;
      background: url('../../../../assets/icon_left_disable.png') no-repeat;
      background-size: contain;
      z-index: 10;
      transition: background 0.3s;
    }
    .active-right {
      cursor: pointer;
      width: 50px;
      height: 50px;
      background: url('../../../../assets/icon_right.png') no-repeat;
      background-size: contain;
      opacity: 1;
      &:hover {
        width: 50px;
        height: 50px;
        background: url('../../../../assets/icon_right_hover.png') no-repeat;
        background-size: contain;
        z-index: 10;
      }
    }
    .active-left {
      cursor: pointer;
      width: 50px;
      height: 50px;
      background: url('../../../../assets/icon_left.png') no-repeat;
      background-size: contain;
      opacity: 1;
      &:hover {
        width: 50px;
        height: 50px;
        background: url('../../../../assets/icon_left_hover.png') no-repeat;
        background-size: contain;
        z-index: 10;
      }
    }
  }
}
.technology-zh {
  .title {
    font-family: MyNotoRegular;
    font-size: 68px;
    font-weight: 400;
  }
  .subtitle {
    font-family: MyNotoRegular;
    font-weight: 400;
    font-size: 23px;
    font-style: normal;
  }
  .technology-content {
    .technologySwiper {
      .techSwiper-slide {
        .slide-decs {
          font-family: MyNoto;
          font-weight: 300;
          font-size: 20px;
          line-height: 32px;
        }
      }
    }
  }
}
@media (min-width: 769px) and (max-width: 1000px) {
  .technology {
    width: 100% !important;
    &-content {
      .technologySwiper {
        .techSwiper-slide {
          // max-width: 600px !important;
          .slide-source {
            // width: 570px !important;
            /* 设置宽高比为 16:9 */
          }
        }
      }
    }
  }
}

/* 适用于屏幕宽度大于768px的情况 */
@media (min-width: 769px) and (max-width: 1230px) {
  .technology {
    .title {
      font-size: 50px;
    }
    &-content {
      padding-bottom: 15%;
      .technologySwiper {
        .techSwiper-slide {
          .slide-source {
            position: relative;
            /* 设置宽高比为 16:9 */
            padding-bottom: 68.5%; /* (9/16) * 100% */
            height: 0;
          }
          font-size: 32px;
        }
      }
    }
  }
}
</style>
