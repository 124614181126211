<script setup lang="ts">
import { onMounted, onBeforeUnmount, ref } from 'vue';

import { Swiper, SwiperSlide } from 'swiper/vue';
import { Autoplay } from 'swiper/modules';

import Footer from '@/components/FooterPhone.vue';
import Navbar from '@/components/NavbarPhone.vue';
import RouterModal from '@/components/RouterModal.vue';
import FormModal from '@/components/FormModal.vue';
import CookieModal from '@/components/CookieModal.vue';

// import Read from '@/assets/ic-read.png';
import ReadColor from '@/assets/ic_read_normal.png';

import { useI18n } from 'vue-i18n';

import { observeFun } from '@/utils';

const modules = ref([Autoplay]);
const { t, locale } = useI18n();
const mySwiper = ref<any>(null);
const mySwiper1 = ref<any>(null);
const currentIndex = ref(0);

let resetTimer: any = null;
let observers = [];
const progressBar = ref<any>(null);
const progressBar1 = ref<any>(null);
const sections = ref(['title-1']);
const isScroll = ref<any>('');
const activeSection = ref<any[]>([]);
const fadeClass = ref('');
const showNavBar = ref(true);
const showMenu = ref(false);
const showForm = ref(false);

// 定义一个函数获取当前设备的宽度
const getWinSize = () => {
  let width = document.documentElement.clientWidth;
  // 假设设计稿宽度为750px
  // 假设已知根元素我们设置为100px（这里设置100方便后续我们好计算）
  // 动态设置根元素html的fontSize
  document.documentElement.style.fontSize = 100 * (width / 430) + 'px';
  console.log(100 * (width / 430) + 'px');
};

const handleScroll = () => {
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  const viewportHeight = (document as any)
    .getElementById('banner')
    .getBoundingClientRect().height;
  if (scrollTop > viewportHeight / 1.8) {
    // Scroll down
    showNavBar.value = false;
  } else {
    // Scroll up
    showNavBar.value = true;
  }
};

const closeHandle = () => {
  showMenu.value = false;
};

const onSwiper = (swiper) => {
  mySwiper.value = swiper; // Swiper初始化时设置实例
};

const onSwiper1 = (swiper) => {
  mySwiper1.value = swiper; // Swiper初始化时设置实例
};

const slideChange = (swiper) => {
  // 获取当前的索引
  const newIndex = swiper.realIndex; // 如果是 loop 模式，获取实际的索引

  // 如果当前的索引和上次的索引不同，才执行
  if (currentIndex.value !== newIndex) {
    console.log('Slide changed:', newIndex);
    currentIndex.value = newIndex; // 更新索引
  }
};

const onAutoplayTimeLeft = (s, time, progress) => {
  console.log(s, progress);
  const maxWidth = 380; // 进度条的最大宽度
  const percentage = time / 6000; // 计算进度百分比
  // 动态调整进度条的宽度
  progressBar.value.style.width = `${maxWidth * (1 - percentage)}px`;

  // 当进度条填满时，等待3秒后重置
  if (percentage >= 1) {
    // 清除之前的定时器
    if (resetTimer) {
      clearTimeout(resetTimer);
    }

    // 延时3秒后重置进度条宽度
    resetTimer = setTimeout(() => {
      progressBar.value.style.width = '0px'; // 重置进度条宽度
    }, 6000); // 等待3秒后重置
  }
};

const onAutoplayTimeLeft1 = (s, time, progress) => {
  console.log(s, progress);
  const maxWidth = 430; // 进度条的最大宽度
  const percentage = time / 4000; // 计算进度百分比
  // 动态调整进度条的宽度
  progressBar1.value.style.width = `${maxWidth * (1 - percentage)}px`;

  // 当进度条填满时，等待3秒后重置
  if (percentage >= 1) {
    // 清除之前的定时器
    if (resetTimer) {
      clearTimeout(resetTimer);
    }

    // 延时3秒后重置进度条宽度
    resetTimer = setTimeout(() => {
      progressBar1.value.style.width = '0px'; // 重置进度条宽度
    }, 4000); // 等待3秒后重置
  }
};

const pauseAutoplay = () => {
  if (mySwiper.value) {
    // mySwiper.value.autoplay.stop(); // 停止自动播放
  }
};

const startAutoplay = () => {
  if (mySwiper.value) {
    // mySwiper.value.autoplay.start(); // 停止自动播放
  }
};

const pauseHandle = () => {
  if (mySwiper1.value) {
    // mySwiper1.value.autoplay.stop(); // 停止自动播放
  }
};

const startHandle = () => {
  if (mySwiper1.value) {
    // mySwiper1.value.autoplay.start(); // 停止自动播放
  }
};

onMounted(() => {
  //进入页面默认获取一次
  getWinSize();
  //当页面窗口视图改变的时候再次执行
  window.onresize = getWinSize;
  window.addEventListener('scroll', handleScroll);
  observeFun(sections, activeSection, observers);
  setTimeout(() => {
    fadeClass.value = 'fade-up';
  }, 500);
});
onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleScroll);
});
</script>

<template>
  <div class="product" :class="{ 'product-zh': locale === 'zh' }">
    <Navbar
      v-if="showNavBar"
      @change="showMenu = true"
      :flag="showMenu"
      @close="closeHandle"
    />
    <div class="product-banner" id="banner">
      <img
        src="https://oss-cn-beijing.galbot.com/online/portal/img/product/banenr-phone.png"
        alt=""
      />
      <div class="text" :class="fadeClass">
        <div class="text-div">
          <div>
            <span>{{ t('product.phoneBanner[0]') }}</span>
          </div>
          <div v-if="locale === 'zh'">{{ t('product.phoneBanner[1]') }}</div>
          <div v-else>
            {{ t('product.phoneBanner[1]') }}{{ t('product.phoneBanner[2]') }}
          </div>
          <div v-if="locale === 'zh'">{{ t('product.phoneBanner[2]') }}</div>
          <div>{{ t('product.phoneBanner[3]') }}</div>
          <div>{{ t('product.phoneBanner[4]') }}</div>
          <div class="contact" @click="showForm = true">
            {{ t('product.contact') }}
            <img :src="ReadColor" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="product-main">
      <div class="main-item">
        <swiper
          :autoplay="{
            delay: 6000,
            disableOnInteraction: false,
          }"
          :loop="true"
          :pagination="{
            clickable: true,
          }"
          :modules="modules"
          class="mySwiper"
          @touchstart="pauseAutoplay"
          @touchend="startAutoplay"
          @swiper="onSwiper"
          @autoplayTimeLeft="onAutoplayTimeLeft"
        >
          <swiper-slide>
            <div>
              <img
                src="https://oss-cn-beijing.galbot.com/online/portal/img/product/swiper1-phone.png"
                alt=""
              />
              <div class="slide-right">
                <span class="slide-right-title">
                  {{ t('product.section1[0].title') }}
                </span>
                <span class="slide-right-decs">
                  {{ t('product.section1[0].decs') }}
                </span>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div>
              <img
                src="https://oss-cn-beijing.galbot.com/online/portal/img/product/swiper2-phone.png"
                alt=""
              />
              <div class="slide-right">
                <span class="slide-right-title">
                  {{ t('product.section1[1].title') }}
                </span>
                <span class="slide-right-decs">
                  {{ t('product.section1[1].decs') }}
                </span>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div>
              <img
                src="https://oss-cn-beijing.galbot.com/online/portal/img/product/swiper3-phone.jpeg"
                alt=""
              />
              <div class="slide-right">
                <span class="slide-right-title">
                  {{ t('product.section1[2].title') }}
                </span>
                <span class="slide-right-decs">
                  {{ t('product.section1[2].decs') }}
                </span>
              </div>
            </div>
          </swiper-slide>
          <template #container-end>
            <div class="autoplay-progress">
              <svg viewBox="0 0 300 2">
                <defs>
                  <linearGradient
                    id="progress-gradient"
                    x1="0%"
                    y1="0%"
                    x2="100%"
                    y2="0%"
                  >
                    <stop
                      offset="0%"
                      style="stop-color: #a264ff; stop-opacity: 1"
                    />
                    <stop
                      offset="25%"
                      style="stop-color: #83a7ff; stop-opacity: 1"
                    />
                    <stop
                      offset="50%"
                      style="stop-color: #83a7ff; stop-opacity: 1"
                    />
                    <stop
                      offset="100%"
                      style="stop-color: #cef7ff; stop-opacity: 1"
                    />
                  </linearGradient>
                </defs>
                <rect x="0" y="0" width="300" height="2" fill="#e0e0e0" />
                <rect
                  x="0"
                  ref="progressBar"
                  y="0"
                  width="0"
                  height="2"
                  fill="url(#progress-gradient)"
                  class="progress-bar"
                />
              </svg>
            </div>
          </template>
        </swiper>
      </div>
      <div class="main-item scene">
        <div class="title" id="title-1">
          <div :class="{ 'fade-up': activeSection.includes('title-1') }">
            {{ t(`product.logistics[${currentIndex}].title`) }}
          </div>
        </div>
        <div
          class="subtitle"
          :class="{ 'fade-up': activeSection.includes('title-1') }"
        >
          {{ t(`product.logistics[${currentIndex}].decs`) }}
        </div>
        <swiper
          :autoplay="{
            delay: 4000,
            disableOnInteraction: false,
          }"
          :loop="true"
          :pagination="{
            clickable: true,
          }"
          :modules="modules"
          class="mySwiper1"
          @touchstart="pauseHandle"
          @touchend="startHandle"
          @swiper="onSwiper1"
          @slideChange="slideChange"
          @autoplayTimeLeft="onAutoplayTimeLeft1"
        >
          <swiper-slide>
            <img
              class="img"
              src="https://oss-cn-beijing.galbot.com/online/portal/img/product/swiper4-phone.png"
              alt=""
            />
          </swiper-slide>
          <swiper-slide>
            <img
              class="img"
              src="https://oss-cn-beijing.galbot.com/online/portal/img/product/swiper5-phone.png"
              alt=""
            />
          </swiper-slide>
          <swiper-slide>
            <img
              class="img"
              src="https://oss-cn-beijing.galbot.com/online/portal/img/product/swiper6-phone.png"
              alt=""
            />
          </swiper-slide>
          <swiper-slide>
            <img
              class="img"
              src="https://oss-cn-beijing.galbot.com/online/portal/img/product/swiper7-phone.png"
              alt=""
            />
          </swiper-slide>
          <template #container-end>
            <div class="autoplay-progress1">
              <svg viewBox="0 0 300 2">
                <defs>
                  <linearGradient
                    id="progress-gradient"
                    x1="0%"
                    y1="0%"
                    x2="100%"
                    y2="0%"
                  >
                    <stop
                      offset="0%"
                      style="stop-color: #a264ff; stop-opacity: 1"
                    />
                    <stop
                      offset="25%"
                      style="stop-color: #83a7ff; stop-opacity: 1"
                    />
                    <stop
                      offset="50%"
                      style="stop-color: #83a7ff; stop-opacity: 1"
                    />
                    <stop
                      offset="100%"
                      style="stop-color: #cef7ff; stop-opacity: 1"
                    />
                  </linearGradient>
                </defs>
                <rect x="0" y="0" width="300" height="2" fill="#e0e0e0" />
                <rect
                  x="0"
                  ref="progressBar1"
                  y="0"
                  width="0"
                  height="2"
                  fill="url(#progress-gradient)"
                  class="progress-bar1"
                />
              </svg>
            </div>
          </template>
        </swiper>
      </div>
      <div class="main-item bg">
        <div class="content">
          <div class="content-info">
            <span>G1</span>
            <span>{{ t('product.section2[0].span') }}</span>
            <div>
              {{ t('product.section2[1].span') }}
              <span>{{ t('product.section2[1].unit') }}</span>
            </div>
            <div>
              {{ t('product.section2[2].span') }}
              <span>{{ t('product.section2[2].unit') }}</span>
            </div>
            <div>
              {{ t('product.section2[3].span') }}
              <span>{{ t('product.section2[3].unit') }}</span>
            </div>
            <div>
              {{ t('product.section2[4].span') }}
              <span>{{ t('product.section2[4].unit') }}</span>
            </div>
            <div>
              {{ t('product.section2[5].span') }}
              <span>{{ t('product.section2[5].unit') }}</span>
            </div>
            <div>
              {{ t('product.section2[6].span') }}
              <span>{{ t('product.section2[6].unit') }}</span>
            </div>
            <div>
              {{ t('product.section2[7].span') }}
              <span>{{ t('product.section2[7].unit') }}</span>
            </div>
            <div>
              {{ t('product.section2[8].span') }}
              <span>{{ t('product.section2[8].unit') }}</span>
              {{ t('product.section2[8].span1') }}
            </div>
          </div>
        </div>
        <div class="contact-us" @click="showForm = true">
          {{ t('product.contact') }}
          <img :src="ReadColor" alt="" />
        </div>
      </div>
    </div>
    <Footer
      @change="showForm = true"
      :isScroll="isScroll"
      @onDefault="isScroll = ''"
    />
    <RouterModal :class="showMenu ? 'active' : ''" @close="closeHandle" />
    <FormModal
      :class="showForm ? 'active-form' : ''"
      @close="showForm = false"
    />
    <CookieModal />
  </div>
</template>

<style lang="scss" scoped>
.product {
  width: 100%;
  &-banner {
    position: relative;
    width: 100vw;
    height: 100vh;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .text {
      width: 4rem;
      position: absolute;
      left: 0.24rem;
      bottom: 0.65rem;
      opacity: 0;
      transform: translateY(80%);
      transition: opacity 1.5s, transform 1s;
      div {
        color: #fff;
        font-family: MyRegular;
        font-style: normal;
        font-weight: 400;
        line-height: 0.36rem;
        font-size: 0.17rem;
        span {
          font-size: 0.17rem;
          background: linear-gradient(
            to right,
            #a264ff,
            #83a7ff,
            #cef7ff,
            #eefcff,
            #ffffff
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          display: inline-block;
        }
        &:first-child {
          span {
            font-size: 0.33rem;
          }
        }
      }
      .contact {
        margin-top: 0.1rem;
        cursor: pointer;
        display: flex;
        align-items: center;
        color: #fff;
        font-family: MyRegular;
        font-size: 0.16rem;
        font-style: normal;
        font-weight: 300;
        -webkit-tap-highlight-color: transparent !important;
        tap-highlight-color: transparent !important;
        outline: none;
        &:focus {
          outline: none;
          background: transparent;
          -webkit-tap-highlight-color: transparent;
        }
        &:active {
          background: transparent;
          outline: none;
          -webkit-tap-highlight-color: transparent;
        }
        img {
          width: 0.24rem;
          height: 0.24rem;
          margin-left: 0.08rem;
        }
      }
    }
    .fade-up {
      opacity: 1 !important;
      transform: translateY(0) !important;
    }
  }
  &-main {
    max-width: 100%;
    margin: 0 auto;
    :deep(.mySwiper .swiper-pagination) {
      width: auto;
      height: 0.1rem;
      top: 2.8rem;
      padding-left: 4%;
      text-align: left;
    }
    :deep(.mySwiper1 .swiper-pagination) {
      // top: 2.7rem;
    }
    :deep(.swiper-pagination-bullet) {
      margin: 0 0.08rem;
      width: 0.1rem;
      height: 0.1rem;
      border-radius: 0.1rem;
      background: #808080;
    }
    :deep(.mySwiper .swiper-pagination-bullet-active) {
      background: #000;
    }
    :deep(.swiper-pagination-bullet-active) {
      background: #fff;
    }
    .swiper {
      width: 100%;
      height: 100vh;
      .autoplay-progress {
        left: 4%;
        position: absolute;
        top: 2.8rem;
        width: 1.8rem;
        height: 2cap;
        z-index: 10;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        color: red;
      }
      .autoplay-progress1 {
        left: 50%;
        transform: translateX(-50%);
        position: absolute;
        bottom: -0.4rem;
        width: 1.8rem;
        height: 2cap;
        z-index: 10;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        color: red;
      }
      .autoplay-progress svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
      .autoplay-progress1 svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
      .progress-bar {
        transition: width 0.2s ease-in-out; /* Smooth transition for width change */
      }
      .progress-bar1 {
        transition: width 0.2s ease-in-out; /* Smooth transition for width change */
      }
    }
    .mySwiper1 {
      width: 100%;
      height: 7.6rem;
    }
    .swiper-slide {
      width: 100%;
      text-align: center;
      font-size: 0.16rem;
      /* Center slide text vertically */
      display: flex;
      > div {
        position: relative;
        width: 100%;
        height: 100%;
        img {
          position: relative;
          width: 100%;
          height: 100%;
          z-index: 1;
        }
        .slide-right {
          text-align: left;
          width: 92%;
          position: absolute;
          top: 0.4rem;
          transform: translateX(-50%);
          z-index: 10;
          left: 50%;
          // transition: right 1s ease-in-out;
          &-title {
            font-family: 'Arial';
            display: block;
            font-size: 0.4rem;
            color: #000;
            margin-bottom: 0.2rem;
          }
          &-decs {
            display: block;
            width: 96%;
            font-family: MyRegular;
            line-height: 0.26rem;
            font-size: 400px;
            font-size: 0.16rem;
            color: #444;
          }
        }
        .active {
          right: 75px;
        }
      }
      .img {
        position: relative;
        margin: 0 auto;
        width: 100%;
        height: 6.87rem;
        object-fit: contain;
      }
    }

    .swiper-slide img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .main-item {
      position: relative;
      width: 100%;
      width: 100%;
      max-height: calc(var(--vh, 1vh) * 100);
      aspect-ratio: 16 / 9;
      .title {
        padding: 0.3rem 0.3rem 0 0.2rem;
        color: #fff;
        font-family: Arial;
        font-size: 0.34rem;
        font-style: normal;
        font-weight: 400;
        text-align: left;
        margin-bottom: 0.1rem;
        > div {
          opacity: 0;
          transform: translateY(110%);
          transition: opacity 1s, transform 0.5s;
        }
      }
      .subtitle {
        padding: 0rem 0.2rem 0.3rem 0.2rem;
        color: #fff;
        font-family: MyRegular;
        font-size: 0.2rem;
        text-align: left;
        line-height: 0.23rem;
        font-weight: 600;
        opacity: 0;
        transform: translateY(110%);
        transition: opacity 1s, transform 0.5s;
      }
      .fade-up {
        opacity: 1 !important;
        transform: translateY(0) !important;
      }
      .content {
        padding-top: 0.3rem;
        box-sizing: border-box;
        position: relative;

        width: 100%;
        display: flex;
        justify-content: space-between;
        height: 100%;
        margin: 0 auto;
        img {
          height: 6.87rem;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 3%;
        }
        > .content-info {
          padding: 0 0.3rem 0 0.4rem;
          position: absolute;
          display: flex;
          flex-direction: column;
          > div {
            position: relative;
            font-family: MyRegular;
            font-size: 0.16rem;
            color: rgba(65, 65, 65, 1);
            margin-bottom: 0.11rem;
            &::after {
              position: absolute;
              top: 0.1rem;
              left: -0.2rem;
              content: '';
              width: 0.06rem;
              height: 0.06rem;
              border-radius: 0.1rem;
              background: #83a7ff;
            }
          }
          > span:first-child {
            position: relative;
            top: -0.1rem;
            position: relative;
            font-family: Arial Hebrew Scholar, Arial;
            font-size: 1.2rem;
            color: #212121;
            left: -0.3rem;
            // padding-top: 0.45rem;
            &::after {
              height: 0;
            }
          }
          > span:nth-child(2) {
            position: relative;
            left: -0.2rem;
            top: -0.15rem;
            font-size: 0.28rem;
            color: #212121;
            &::after {
              height: 0;
            }
          }

          .span {
            margin-bottom: 0.12rem;
          }
        }
      }
    }
    .scene {
      // height: 9rem;
      max-height: 20rem;
    }
    .bg {
      position: relative;
      height: 9.32rem;
      background: url('https://oss-cn-beijing.galbot.com/online/portal/img/product/bgc-phone.png')
        no-repeat;
      background-size: cover;
      max-height: none;
      .contact-us {
        position: absolute;
        right: 0.4rem;
        bottom: 0.5rem;
        margin-top: 26px;
        cursor: pointer;
        display: flex;
        align-items: center;
        color: #414141;
        font-family: MyRegular;
        font-size: 0.18rem;
        font-style: normal;
        font-weight: 300;
        -webkit-tap-highlight-color: transparent !important;
        tap-highlight-color: transparent !important;
        outline: none;
        &:focus {
          outline: none;
          background: transparent;
          -webkit-tap-highlight-color: transparent;
        }
        &:active {
          background: transparent;
          outline: none;
          -webkit-tap-highlight-color: transparent;
        }
        img {
          width: 0.24rem;
          height: 0.24rem;
          margin-left: 0.08rem;
        }
      }
    }
  }
}
.product-zh {
  .product-banner {
    .text {
      div {
        span {
          font-family: Arial;
        }
      }
    }
  }

  .product-main {
    :deep(.mySwiper .swiper-pagination) {
      top: 1.6rem;
    }
    .autoplay-progress {
      top: 1.8rem;
    }
  }
  .content {
    .content-info {
      > span:first-child {
        font-family: Arial Hebrew Scholar, Arial;
      }
      > span:nth-child(2) {
        font-family: Arial Hebrew Scholar, Arial;
      }
      div {
        font-family: MyRegular;
        span {
          font-family: Arial;
        }
      }
    }
  }
}
</style>
