// router/index.js
import { createRouter, createWebHistory } from 'vue-router'
import { useLanguageStore } from '@/store/languageStore';

// 导入你的组件
import Home from '@/views/Home/index.vue'
import About from '@/views/About/index.vue'
import Product from '@/views/Product/index.vue'
import News from '@/views/News/index.vue'
import Careers from '@/views/Careers/index.vue'
import Privacy from '@/views/Agreement/Privacy/index.vue'
import Agreement from '@/views/Agreement/User/index.vue'



// 定义路由
const routes = [
  {
    path: '/', name: 'Home', component: Home, meta: {
      title: 'Galbot-Official Website',
      titleZh: 'Galbot-银河通用机器人官方网站',
      description: 'Galbot-Empower various industries and serve every home with embodied AGI robots',
      descriptionZh: 'Galbot银河通用机器人-具身多模态大模型通用机器人服务千行百业、千家万户',
      keywords: 'Galbot, galbot, GALBOT, Galbot Official Website, G1, GALBOT APPLICATIONS, embodied AGI robots',
      keywordsZh: '银河通用机器人官网, 银河通用机器人, 北京银河通用, 银河通用, Galbot, galbot, GALBOT, Galbot应用场景'
    },
  },
  {
    path: '/g1', name: 'G1', component: Product, meta: {
      title: 'Galbot-G1',
      titleZh: 'Galbot-银河通用机器人产品介绍',
      description: 'Galbot-G1, A Generalized-Operational Embodied Large-Model Robot, Adeptly Handles Various Complex Scenarios, Born To Better Serve Humanity.',
      descriptionZh: 'Galbot-银河通用机器人产品G1，可泛化操作的具身大模型机器人，能灵活应对各种复杂场景，为更好地服务人类而生。',
      keywords: 'Galbot, Co., Ltd, embodied AI, general-purpose robots',
      keywordsZh: 'Galbot, 北京银河通用机器人有限公司, 具身智能, 智能机器人'
    }
  },
  {
    path: '/about', name: 'About', component: About, meta: {
      title: 'Galbot-About Us',
      titleZh: 'Galbot-银河通用机器人公司介绍',
      description: 'Galbot is an innovative company specializing in Embodied Multimodal Large Model robots',
      descriptionZh: 'Galbot银河通用机器人-银河通用是一家具身多模态大模型机器人创新企业',
      keywords: 'Galbot, galbot, GALBOT, Galbot Co., Ltd, embodied AI, general-purpose robots',
      keywordsZh: 'Galbot, galbot, GALBOT, 北京银河通用机器人有限公司, 具身智能, 智能机器人'
    }
  },
  {
    path: '/news', name: 'News', component: News, meta: {
      title: 'Galbot-News',
      titleZh: 'Galbot-银河通用机器人新闻动态',
      description: 'Galbot-Get the latest Galbot updates',
      descriptionZh: 'Galbot银河通用机器人-关注银河通用机器人最新资讯',
      keywords: 'GALBOT NEWS, galbot news, Galbot latest news',
      keywordsZh: 'Galbot新闻, Galbot新闻动态, Galbot最新新闻'
    }
  },
  {
    path: '/careers', name: 'Careers', component: Careers, meta: {
      title: 'Galbot-Galbot Recruits Genius',
      titleZh: 'Galbot-银河通用机器人招贤纳士',
      description: 'Galbot Recruits Genius-Welcome to focus on the open positions and join us',
      descriptionZh: 'Galbot银河通用机器人招聘-欢迎关注热招岗位，加入我们，启航非凡之旅',
      keywords: 'WORKING AT GALBOT, GALBOT OPEN POSITIONS, Galbot Compus Recruitment, Galbot Social Recruitment',
      keywordsZh: 'Galbot工作环境, Galbot热招岗位, Galbot校园招聘, Galbot社会招聘'
    }
  },
  { path: '/privacy', name: 'Privacy', component: Privacy },
  { path: '/agreement', name: 'agreement', component: Agreement },
]

// 创建路由实例
const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL), // 使用 HTML5 历史模式
  routes, // 使用上面定义的路由规则import { Home } from '@/views/Home.vue';

})
router.beforeEach((to: any, from, next) => {
  const languageStore = useLanguageStore();
  console.log(from, 'from')
  // 设置页面标题
  if (to.meta.title) {
    (document as any).title = languageStore.language === 'zh' ? to.meta.titleZh : to.meta.title;
  }

  // 设置描述和关键词
  const metaDescription = document.querySelector('meta[name="description"]');
  const metaKeywords = document.querySelector('meta[name="keywords"]');

  if (metaDescription) {
    metaDescription.setAttribute('content', languageStore.language === 'zh' ? to.meta.descriptionZh || '' : to.meta.description || '');
  }

  if (metaKeywords) {
    metaKeywords.setAttribute('content', languageStore.language === 'zh' ? to.meta.keywordsZh || '' : to.meta.keywords || '');
  }

  next();
});
export default router
