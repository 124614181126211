<script setup lang="ts">
import { onMounted, onBeforeUnmount, ref } from 'vue';

import Story from './story.vue';
import Team from './team.vue';
import News from './news.vue';
import Investor from './investor.vue';
import Technology from './technology.vue';
import Footer from '@/components/FooterPhone.vue';

import 'swiper/css';
import Pic3 from '@/assets/pic_3.png';
import Background from '@/assets/background.png';

import Navbar from '@/components/NavbarPhone.vue';
import RouterModal from '@/components/RouterModal.vue';
import FormModal from '@/components/FormModal.vue';
import CookieModal from '@/components/CookieModal.vue';

import { observeFun } from '@/utils';
import { useI18n } from 'vue-i18n';

const { t, locale } = useI18n();
let observers: any = [];
const sections = ref([
  'title-1',
  'title-2',
  'title-3',
  'title-4',
  'title-5',
  'title-6',
]);
const activeSection = ref<any[]>([]);
const fadeClass = ref('');
const showNavBar = ref(true);
const showMenu = ref(false);
const showForm = ref(false);
// 定义一个函数获取当前设备的宽度
const getWinSize = () => {
  let width = document.documentElement.clientWidth;
  // 假设设计稿宽度为750px
  // 假设已知根元素我们设置为100px（这里设置100方便后续我们好计算）
  // 动态设置根元素html的fontSize
  document.documentElement.style.fontSize = 100 * (width / 430) + 'px';
};

const handleScroll = () => {
  const scrollTop = window.pageYOffset || document?.documentElement.scrollTop;
  const viewportHeight: any = document
    ?.getElementById('banner')
    ?.getBoundingClientRect()?.height;
  if (scrollTop > viewportHeight / 1.8) {
    // Scroll down
    showNavBar.value = false;
  } else {
    // Scroll up
    showNavBar.value = true;
  }
};

const closeHandle = () => {
  showMenu.value = false;
};

onMounted(() => {
  //进入页面默认获取一次
  getWinSize();
  //当页面窗口视图改变的时候再次执行
  window.onresize = getWinSize;
  window.addEventListener('scroll', handleScroll);
  observeFun(sections, activeSection, observers);
  setTimeout(() => {
    fadeClass.value = 'fade-up';
  }, 500);
});
onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleScroll);
});
</script>

<template>
  <div class="about" :class="{ 'about-zh': locale === 'zh' }">
    <Navbar v-if="showNavBar" @change="showMenu = true" :flag="showMenu" @close="closeHandle" />
    <div class="about-banner" id="banner">
      <img :src="Background" alt="galaxy" class="bg-container" />

      <div class="text" :class="fadeClass">
        <div>
          <div>
            <span>{{ t('about.phoneBanner[0]') }}</span>
            {{ t('about.phoneBanner[1]') }}
          </div>
          <div>{{ t('about.phoneBanner[2]') }}</div>
          {{ t('about.phoneBanner[3]') }}
        </div>
      </div>
    </div>
    <div class="about-main">
      <div class="us">
        <div class="title" id="title-1">
          <div :class="{ 'fade-up': activeSection.includes('title-1') }">
            {{ t('about.us.title') }}
          </div>
        </div>
        <div
          class="subtitle"
          :class="{ 'fade-up': activeSection.includes('title-1') }"
        >
          {{ t('about.us.decs') }}
        </div>
        <div
          class="us-content flex-between"
          :class="{ 'fade-up': activeSection.includes('title-1') }"
        >
          <img :src="Pic3" alt="" />
          <div class="list">
            <div>
              {{ t('about.us.infos[0]') }}
            </div>
            <div>
              {{ t('about.us.infos[1]') }}
            </div>
            <div>
              {{ t('about.us.infos[2]') }}
            </div>
            <div>
              {{ t('about.us.infos[3]') }}
            </div>
          </div>
        </div>
      </div>
      <Story :activeSection="activeSection" />
      <Team :activeSection="activeSection" />
      <Technology :activeSection="activeSection" />
      <Investor :activeSection="activeSection" />
      <News :activeSection="activeSection" />
    </div>
    <Footer @change="showForm = true" />
    <RouterModal :class="showMenu ? 'active' : ''" @close="closeHandle" />
    <FormModal
      :class="showForm ? 'active-form' : ''"
      @close="showForm = false"
    />
    <CookieModal />
  </div>
</template>

<style lang="scss" scoped>
.about {
  width: 100%;
  &-banner {
    position: relative;
    width: 100vw;
    height: 100vh;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    // height: calc(var(--vh, 1vh) * 100);
    overflow: hidden;
    .bg-container {
      width: 100%;
      position: absolute;
      top: 30%;
      display: flex;
      align-items: center;
      justify-content: center;
      animation: rotateAndScale 20s ease-in-out infinite;
      -webkit-animation: rotateAndScale 20s ease-in-out infinite;
      /* Chrome, Safari, Opera */
      -moz-animation: rotateAndScale 20s ease-in-out infinite;
      /* Firefox */
      -o-animation: rotateAndScale 20s ease-in-out infinite;
      /* Opera */
      background-clip: padding-box;
      -moz-background-clip: padding-box;
    }
    .text {
      position: absolute;
      left: 0.24rem;
      bottom: 0.65rem;
      opacity: 0;
      transform: translateY(80%);
      transition: opacity 1.5s, transform 1s;
      div {
        color: #fff;
        font-family: MyRegular;
        font-size: 0.26rem;
        font-style: normal;
        font-weight: 400;
        line-height: 0.36rem; /* 112.069% */
        span {
          background: linear-gradient(
            to right,
            #a264ff,
            #83a7ff,
            #cef7ff,
            #eefcff,
            #ffffff
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          display: inline-block;
        }
      }
    }
  }
  .fade-up {
    opacity: 1 !important;
    transform: translateY(0) !important;
  }
  &-main {
    max-width: 100%;
    margin: 0 auto;
    .us {
      margin-top: 0.5rem;
      .title {
        color: #fff;
        font-family: Arial;
        font-size: 0.33rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: center;
        > div {
          opacity: 0;
          transform: translateY(110%);
          transition: opacity 1s, transform 0.5s;
        }
      }
      .subtitle {
        width: 3rem;
        padding: 0.12rem 0.24rem 0 0.24rem;
        font-family: MyRegular;
        color: rgba(180, 188, 208, 0.55);
        font-size: 0.15rem;
        font-style: italic;
        font-weight: 600;
        line-height: 0.2rem;
        margin: 0 auto;
        text-align: center;
        opacity: 0;
        transform: translateY(110%);
        transition: opacity 1s, transform 0.5s;
      }
      &-content {
        padding: 0.17rem 0.24rem 0.5rem 0.24rem;
        box-sizing: border-box;
        opacity: 0;
        transform: translateY(100%);
        transition: opacity 2s, transform 1.5s;
        overflow: hidden;
        img {
          display: grid;
          width: 3.82rem;
          margin-bottom: 0.18rem;
        }
        .list {
          div {
            font-family: MyRegular;
            font-size: 0.14rem;
            color: #a4abbd;
            line-height: 0.2rem;
            font-weight: 300;
            margin-bottom: 0.12rem;
            // margin-bottom: 0.26rem;
          }
        }
      }
    }
  }
}
.about-zh {
  .text {
    > div {
      font-family: MyNotoRegular;
      font-size: 0.26rem;
      font-weight: 400;
      span {
        font-family: MyNotoRegular;
        font-size: 0.26rem;
        font-weight: 400;
      }
    }
    .us-content {
    }
  }
  .us .title {
    font-size: 0.3rem;
    font-family: MyNotoRegular;
  }
  .us .subtitle {
    font-family: MyNotoRegular;
    font-size: 0.14rem;
    font-weight: 400;
    font-style: normal;
  }
  .about-main .us-content .list div {
    font-family: MyNoto;
    line-height: 0.23rem;
  }
}
/* 定义图片收放效果-关键帧动画 */
@keyframes rotateAndScale {
  0% {
    transform: scale(1.2);
    background-size: cover;
  }

  50% {
    transform: scale(2);
    background-size: cover;
  }

  100% {
    transform: scale(1.2);
    background-size: cover;
  }
}

/* Chrome, Safari, Opera 以及较新版本的 Edge */
@-webkit-keyframes rotateAndScale {
  0% {
    transform: scale(1.2);
    background-size: cover;
  }

  50% {
    transform: scale(2);
    background-size: cover;
  }

  100% {
    transform: scale(1.2);
    background-size: cover;
  }
}

/* Firefox */
@-moz-keyframes rotateAndScale {
  0% {
    transform: scale(1.2);
    background-size: cover;
  }

  50% {
    transform: scale(2);
    background-size: cover;
  }

  100% {
    transform: scale(1.2);
    background-size: cover;
  }
}
</style>
