<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

import Close from '@/assets/close.svg';

import { useLanguageStore } from '@/store/languageStore';

const { t } = useI18n();

const language = useLanguageStore();

const router = useRouter();
// 检查 Cookie 是否已经存在
const display = ref(true);
const checkCookie = () => {
  return document.cookie
    .split(';')
    .some((item) => item.trim().startsWith('cookieConsent='));
};

// 设置 Cookie
const setCookie = (name, value, days) => {
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  const expires = 'expires=' + date.toUTCString();
  document.cookie = name + '=' + value + ';' + expires + ';path=/';
};

const clickHandle = () => {
  setCookie('cookieConsent', 'true', 365); // 设置 365 天有效期的 Cookie
  display.value = true;
};

const toAgreement = (path) => {
  router.replace(path);
};

onMounted(() => {
  if (language.ip === '国外') {
    // 检查是否已经同意
    if (!checkCookie()) {
      // 如果没有同意，显示弹框
      display.value = false;
    } else {
      display.value = true;
    }
  }
});
</script>

<template>
  <div class="cookie" :class="{ display }">
    <img :src="Close" alt="" class="cookie-close" @click="display = true" />
    <div class="cookie-content">
      {{ t(`cookie[0]`) }}
      <span @click="toAgreement('/privacy')">&nbsp;{{ t(`cookie[1]`) }}</span>
      <span class="cookie-button" @click="clickHandle">
        {{ t(`cookie[2]`) }}
      </span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cookie {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 90px;
  background: #121212;
  box-sizing: border-box;
  transition: bottom 0.8s ease-in-out, opacity 0.8s ease-in-out;
  border-top: 1px solid #757884;
  &-close {
    cursor: pointer;
    position: absolute;
    right: 15px;
    top: 18px;
    height: 18px;
    width: 18px;
    opacity: 0.6;
  }
  &-content {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    color: rgba(255, 255, 255, 0.6);
    font-family: MyRegularLight;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
    span {
      cursor: pointer;
      font-weight: 600;
      font-family: MyRegularLight;
      font-size: 16px;
      color: rgba(255, 255, 255, 0.6);
      font-style: italic;
      text-decoration-line: underline;
    }
    .cookie-button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 87px;
      height: 32px;
      border-radius: 5px;
      font-style: normal;
      text-decoration-line: none;
      border: 1px solid #f6f6f64d;
      color: rgba(255, 255, 255, 0.6);
      font-family: MyRegularLight;
      font-size: 16px;
      margin-left: 30px;
      font-weight: 600;
      &:hover {
        color: rgba(255, 255, 255, 1);
        border: 1px solid #fff;
      }
    }
  }
}
.display {
  opacity: 0;
  bottom: -8rem;
}
</style>
