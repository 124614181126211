<script setup>
import Close from '@/assets/ic_quit_nomal.png';
import global from '@/assets/global.png';
import { defineEmits } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useLanguageStore } from '@/store/languageStore';

const { t, locale } = useI18n();
const languageStore = useLanguageStore();
const emits = defineEmits(['close']);
const router = useRouter();
const route = useRoute();
const emitClose = () => {
  emits('close');
};
const routeList = ['/g1', '/about', '/news', '/careers'];
const changeZh = () => {
  console.log(route, '----');
  locale.value = 'zh';
  languageStore.setLanguage('zh');
  // 设置页面标题
  if (route.meta.title) {
    document.title = route.meta.titleZh;
  }
  // 设置描述和关键词
  const metaDescription = document.querySelector('meta[name="description"]');
  const metaKeywords = document.querySelector('meta[name="keywords"]');

  if (metaDescription) {
    metaDescription.setAttribute('content', route.meta.descriptionZh || '');
  }

  if (metaKeywords) {
    metaKeywords.setAttribute('content', route.meta.keywordsZh);
  }
  emitClose();
};
const changeEn = () => {
  locale.value = 'en';
  languageStore.setLanguage('en');
  // 设置页面标题
  if (route.meta.title) {
    document.title = route.meta.title;
  }
  // 设置描述和关键词
  const metaDescription = document.querySelector('meta[name="description"]');
  const metaKeywords = document.querySelector('meta[name="keywords"]');

  if (metaDescription) {
    metaDescription.setAttribute('content', route.meta.description || '');
  }

  if (metaKeywords) {
    metaKeywords.setAttribute('content', route.meta.keywords);
  }
  emitClose();
};

const jumpHandle = (item) => {
  console.log(item, 'item');
  router.push({ path: item });
  emitClose();
};
</script>
<template>
  <div class="modal" :class="{ 'modal-zh': locale === 'zh' }">
    <!-- <div class="modal-close">
      <img :src="Close" @click="emitClose" />
    </div> -->
    <div class="modal-content">
      <div
        v-for="(item, index) in routeList"
        :key="index"
        class="modal-content-item"
        :class="{ 'item-active': item === route.path }"
        @click="jumpHandle(item)"
      >
        {{ t(`menu[${index}]`) }}
      </div>
    </div>
    <div class="language">
      <!-- <img :src="global" alt="" /> -->
      <div
        @click="changeZh"
        class="language-item"
        :class="{ 'language-active': locale === 'zh' }"
      >
        中文
      </div>
      <span>|</span>
      <div
        @click="changeEn"
        class="language-item"
        :class="{ 'language-active': locale === 'en' }"
      >
        English
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.modal {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: url('https://oss-cn-beijing.galbot.com/online/portal/img/bg3_1.png')
    no-repeat;
  background-size: 100% 100%;
  z-index: -1;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  &-close {
    display: flex;
    position: absolute;
    top: 0.26rem;
    right: 0.26rem;
    border-radius: 4px;
    img {
      width: 0.19rem;
      height: 0.19rem;
    }
  }
  &-content {
    width: 100%;
    padding: 0 0.24rem;
    box-sizing: border-box;
    &-item {
      width: 100%;
      padding: 0.2rem;
      height: 0.78rem;
      box-sizing: border-box;
      /* 预先设置透明的 border-bottom */
      border-bottom: 0.01rem solid transparent;
      /* 应用渐变效果 */
      border-image: linear-gradient(
          to right,
          rgba(238, 238, 238, 0),
          #d8d8d8,
          rgba(238, 238, 238, 0)
        )
        1;
      opacity: 0.7;
      font-family: Arial;
      font-size: 0.33rem;
      color: #e3f1ff;
      text-align: center;
      font-weight: 400;
    }
    .item-active {
      opacity: 1;
      color: #ffffff;
      border-image: linear-gradient(
          to right,
          rgba(238, 238, 238, 0),
          #fff,
          rgba(238, 238, 238, 0)
        )
        1;
    }
  }
  .language {
    position: absolute;
    bottom: 0.5rem;
    font-family: MicrosoftYaHeiUI, Verdana;
    height: 0.22rem;
    display: flex;
    align-items: center;
    color: #ffffff;
    line-height: 0.22rem;
    font-size: 0.24rem;
    font-weight: 400;
    display: flex;
    span {
      position: relative;
      top: -0.02rem;
      opacity: 0.6;
    }
    &-item {
      color: #fff;
      font-size: 0.24rem;
      cursor: pointer;
      opacity: 0.6;
      font-weight: 400;
      margin: 0 0.1rem;
      -webkit-tap-highlight-color: transparent !important;
      tap-highlight-color: transparent !important;
      outline: none;
      &:focus {
        outline: none;
        background: transparent;
        -webkit-tap-highlight-color: transparent;
      }
      &:active {
        background: transparent;
        outline: none;
        -webkit-tap-highlight-color: transparent;
      }
      &:first-child {
        font-family: MyNoto;
      }
      &:last-child {
        position: relative;
        top: 0.03rem;
        font-family: MyRegularLight;
      }
    }

    &-active {
      opacity: 1;
      font-family: Verdana;
      color: #ffffff;
    }
    img {
      width: 0.27rem;
      height: 0.27rem;
    }
  }
}
.modal-zh {
  &-content {
    font-size: 0.26px;
  }
  .modal-content {
    &-item {
      font-family: MyNotoRegular;
    }
  }
}

.active {
  opacity: 1;
  z-index: 9990;
  // transform: translateY(0);
}
</style>
